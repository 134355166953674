import React from 'react'
import {colours} from "../styles/colours";

const pageStyles = {
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0
}

const backgroundStyle = {
    backgroundColor: colours.yellow,
    width: "100vw",
    height: "100vh",
    display: "flex"
}

const mainContentStyle = {
    maxWidth: "500px",
    height: "100vh",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    position: "relative"
}

const notFoundHeaderStyle = {
    marginTop: "100px",
    textAlign: "center",
    width: "100%",
    margin: 0,
    fontSize: "3em",
    color: colours.red,
}

const notFoundMessageStyle = {
    textAlign: "center",
    width: "100%",
    margin: 0
}

const homePageLinkContainer = {
    borderTop: `2px solid ${colours.red}`,
    width: "100%",
    marginTop: "30px",
    paddingTop: "30px"
}

const homePageLink = {
    width: "100%",
    float: "left",
    textDecoration: "none",
    textAlign: "center",
    textShadow: "none",
    backgroundImage: "none",
    color: colours.grey,
    margin: 0,
    padding: 0
}

const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
       <title>Not Found</title>
       <div style={backgroundStyle}>
           <div style={mainContentStyle}>
               <h1 style={notFoundHeaderStyle}>Oops!</h1>
               <h3 style={notFoundMessageStyle}>We couldn't find that page!</h3>
               <div style={homePageLinkContainer}>
                   <a style={homePageLink} href={"https://suzebakes.co.uk"}>
                       <p style={{padding: 0, margin: 0}}>Take me to the main page</p>
                   </a>
               </div>
           </div>
       </div>
   </main>
  )
}

export default NotFoundPage
